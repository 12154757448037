export const LOGIN_STATE_NO_LOGIN       = 1;
export const LOGIN_STATE_LOGIN_SUCCESS  = 2;
export const LOGIN_STATE_INVALID_CHAIN  = 3;
export const LOGIN_STATE_NO_WEB3        = 4;

export const ROPSTEN_CHAIN_ID = 3;
export const ROPSTEN_CHAIN_ID_HEX = "0x3";
export const CONTRACT_ADDRESS = "0x5522719340b037a17f38dc6637ec30cce38c5dfe";

export const ACTION_TYPE_SET_PEER_CONNECTON   = "ACTION_TYPE_SET_PEER_CONNECTION";
export const ACTION_TYPE_SET_PEER_STATE       = "ACTION_TYPE_SET_PEER_STATE";

export const JOIN_STATUS_MAKING_CONNECTION      = "JOIN_STATUS_MAKING_CONNECTION";
export const JOIN_STATUS_GENERATING_TRANSACTION = "JOIN_STATUS_GENERATING_TRANSACTION";
export const JOIN_STATUS_WAITING_FOR_MINT       = "JOIN_STATUS_WAITING_FOR_MINT";
export const JOIN_STATUS_REDIRECTING            = "JOIN_STATUS_REDIRECTING";

export const INFURA_PROJECT_ID = "26dfca216da64ee6b000483ab57de5f6";
export const ETHERSCAN_API_KEY = "XDCWQ2BPR9FH8H39RQU9E8CNMVRS225VS9";

export const CALL_URL_PREPEND_TEXT = "tyd";

export const WALLET_METAMASK      = "WALLET_METAMASK";
export const WALLET_WALLETCONNECT = "WALLET_WALLETCONNECT";
export const WALLET_COINBASE      = "WALLET_COINBASE";
export const WALLET_LEDGER        = "WALLET_LEDGER";
export const WALLET_TREZOR        = "WALLET_TREZOR";

export const WEB3_CALL_REGEX = /tyd-[b-j][a-j]{2}(-[a-j]{3}){2}/gi;

export const PEER_STATE_INITIATOR = "PEER_STATE_INITIATOR";
export const PEER_STATE_JOINEE    = "PEER_STATE_JOINEE";